import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import i18n from "src/translations/i18n";
import { formatDateWithWeekday } from "@utils/formatDateTime";
import { useEventPageData } from "@app/public/EventPage/contexts/EventPage.context";
import { useEventPageModals } from "@app/public/EventPage/contexts/EventPageModals.context";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import ImageViewer from "@components/media/ImageViewer/ImageViewer";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Link from "@components/navigation/Link/Link";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import { createCalendarLinks } from "@utils/calendarLinks";

import "./EventPageSidebar.scss";

const EventPageSidebar = () => {
  const [registrationClosed, setRegistrationClosed] = useState(false);

  const lang = i18n.language;
  const { event, status: eventStatus } = useEventPageData();
  const { openModal } = useEventPageModals();

  const { t } = useTranslation("public");
  const { t: commonT } = useTranslation("common");

  const handleOpenSignUpModal = () => openModal({ type: "signUpForEvent" });

  const eventStartDate = formatDateWithWeekday(event.start, lang, event.timezone);
  const eventEndDate = formatDateWithWeekday(event.end, lang, event.timezone);
  const submissionEndDate = formatDateWithWeekday(event.page.submissionEnd, lang, event.timezone);

  let interval: NodeJS.Timeout;
  const registrationClosedDateMs = event.page.submissionEnd
    ? new Date(event.page.submissionEnd).getTime()
    : new Date(event.start).getTime();

  useEffect(() => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      if (registrationClosed) return clearInterval(interval);
      if (registrationClosedDateMs < now) setRegistrationClosed(true);
    }, 1000);

    return () => clearInterval(interval);
  });

  const calendarLinks = useMemo(() => {
    if (eventStatus !== "success") return [];

    return createCalendarLinks({
      title: event.name,
      description: event.url,
      location: event.location?.address,
      url: event.url,
      startUTC: new Date(event.start).getTime(),
      endUTC: new Date(event.end).getTime(),
      timezone: event.timezone,
      organizerName: event.organization.name,
      organizerEmail: event.organization.email
    });
  }, [event]);

  const signUpButtonDisabled = registrationClosed || event.page.submissionsButtonDisabled;

  return (
    <div className="event-page-sidebar">
      <Stack rowGap={35}>
        {event.page.imageUrl && (
          <ImageViewer src={event.page.imageUrl} alt="event image" className="event-page-sidebar-image" />
        )}

        {event.page.enableSubmissions && (
          <>
            <Tooltip
              tip={commonT("signUpForEventDisabled")}
              hide={!signUpButtonDisabled}
              width={250}
              textCenter
              fullWidth
              className="desktop-md"
            >
              <Button
                variant="primary"
                className="button-signup"
                onClick={handleOpenSignUpModal}
                disabled={signUpButtonDisabled}
              >
                {t("eventPage.sidebar.signUpButton")}
              </Button>
            </Tooltip>

            <div className="mobile-signup-button mobile-md">
              <Tooltip
                tip={commonT("signUpForEventDisabled")}
                hide={!signUpButtonDisabled}
                width={250}
                textCenter
                fullWidth
                displayAfterClick
              >
                <Button
                  variant="primary"
                  className="button-signup"
                  onClick={handleOpenSignUpModal}
                  disabled={signUpButtonDisabled}
                >
                  {t("eventPage.sidebar.signUpButton")}
                </Button>
              </Tooltip>
            </div>
          </>
        )}

        {(event.location.name || event.location.address) && (
          <Stack>
            <Group colGap={13} className="mb-3">
              <img src="/images/icon-event-location-address.svg" alt="event location" />
              <p className="p0 bold">{t("eventPage.sidebar.location")}</p>
            </Group>

            <p className="p0">{event.location.name || "-"} </p>
            <p className="p0 mb-1">{event.location.address || "-"}</p>

            {event.location.url && (
              <Link href={event.location.url} target="_blank">
                {t("eventPage.sidebar.showOnMap")}
              </Link>
            )}
          </Stack>
        )}

        <Stack>
          <Group colGap={13} className="mb-3">
            <img src="/images/icon-event-date.svg" alt="event date" />
            <p className="p0 bold">{t("eventPage.sidebar.date")}</p>
          </Group>

          <Group>
            <p className="p0 date-name">{t("eventPage.sidebar.eventStart")}</p>
            <p className="p0">{eventStartDate}</p>
          </Group>

          <Group>
            <p className="p0 date-name">{t("eventPage.sidebar.eventEnd")}</p>
            <p className="p0">{eventEndDate}</p>
          </Group>

          <Group className="mt-2 black-2">
            <p className="p0 date-name">{t("eventPage.sidebar.submissionEnd")}</p>
            <p className="p0">{submissionEndDate}</p>
          </Group>
        </Stack>

        <Stack>
          <Group colGap={13} className="mb-3">
            <img src="/images/icon-event-organizer.svg" alt="event organizer" />
            <p className="p0 bold">{t("eventPage.sidebar.organizer")}</p>
          </Group>

          {event.logoUrl && (
            <div className="event-page-sidebar-organizer-logo-box">
              <img src={event.logoUrl} alt="organizer logo" />
            </div>
          )}

          <p className="p0">{event.organization.name || "-"}</p>

          <Link
            href={`mailto:${event.organization.email}`}
            title="Orgranizator's email"
            target="_blank"
            imageType="email"
            className="mt-1"
          >
            {event.organization.email}
          </Link>
        </Stack>

        <Stack>
          <Group colGap={13} className="mb-3">
            <img src="/images/icon-event-url.svg" alt="Calendar" />
            <p className="p0 bold">{t("eventPage.sidebar.calendar")}</p>
          </Group>

          <Link
            href={calendarLinks.find((cl) => cl.type === "google")?.url ?? ""}
            title={t("eventPage.sidebar.calendars.google")}
            target="_blank"
          >
            {t("eventPage.sidebar.calendars.google")}
          </Link>

          <Link
            href={calendarLinks.find((cl) => cl.type === "icalendar")?.url ?? ""}
            title={t("eventPage.sidebar.calendars.icalendar")}
            target="_blank"
            download="invitation.ics"
          >
            {t("eventPage.sidebar.calendars.icalendar")}
          </Link>

          <Link
            href={calendarLinks.find((cl) => cl.type === "outlook")?.url ?? ""}
            title={t("eventPage.sidebar.calendars.outlook")}
            target="_blank"
          >
            {t("eventPage.sidebar.calendars.outlook")}
          </Link>
        </Stack>
      </Stack>
    </div>
  );
};

export default EventPageSidebar;
