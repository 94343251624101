import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";

import { ApiKeyDto } from "@services/Api/api.dto";
import { copyToClipboard } from "@utils/copyToClipboard";
import { formatAccessPrivileges } from "../formatAccessPrivileges";
import { formatDateTime } from "@utils/formatDateTime";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";

import "../ApiKeysTable.scss";

interface ApiKeysTableRow {
  apiKey: ApiKeyDto;
}

const ApiKeysTableRow = ({ apiKey }: ApiKeysTableRow) => {
  const [expired, setExpired] = useState(false);

  const { label, hash, access, exp } = apiKey;

  const { openModal } = useSettingsModals();

  const { t } = useTranslation("settingsPage");
  const { t: commonT } = useTranslation("common");

  const handleRemoveApiKey = (apiKey: ApiKeyDto) => () => openModal({ type: "removeApiKey", data: apiKey });

  const accessPrivileges = formatAccessPrivileges(access, t);
  const expirationDateFormatted = exp ? formatDateTime(exp) : t("termoOfValidityEmpty");
  const expirationDate = new Date(exp).getTime();

  let interval: NodeJS.Timeout;

  useEffect(() => {
    if (!exp) return;

    interval = setInterval(() => {
      const now = new Date().getTime();

      if (expired) return clearInterval(interval);
      if (expirationDate < now) setExpired(true);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className="table-row">
      <div className="table-row-cell cell-key-nameKey">
        <p className={`p4 ${expired ? "danger-1" : ""}`}>{label}</p>
      </div>

      <div className="table-row-cell cell-key-hash">
        <Group colGap={10}>
          <p className="apiKeys-hash p4">{hash}</p>
          <Tooltip tip={commonT("copyToClipboard")} position="top">
            <ActionIcon size={30} onClick={() => copyToClipboard(hash)}>
              <img src="/images/icon-copy.svg" alt="copy text button" />
            </ActionIcon>
          </Tooltip>
        </Group>
      </div>

      <div className="table-row-cell cell-key-accessPrivileges">
        <p className="p4">{accessPrivileges}</p>
      </div>

      <div className="table-row-cell cell-key-termOfValidity">
        <p className={`p4 ${expired ? "danger-1" : ""}`}>{expirationDateFormatted}</p>
      </div>

      <div className="table-row-cell cell-key-actions">
        <Group colGap={4}>
          <Tooltip tip={commonT("remove")} position="top">
            <ActionIcon onClick={handleRemoveApiKey(apiKey)}>
              <img src="/images/icon-delete.svg" alt="delete key button" />
            </ActionIcon>
          </Tooltip>

          {expired && (
            <Tooltip tip={commonT("apiKeyExpired")} position="top">
              <ActionIcon color="danger">
                <img src="/images/icon-expired.svg" alt="api key expired" />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </div>
    </div>
  );
};

export default ApiKeysTableRow;
