import React from "react";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ApiClient } from "@services/Api/ApiClient";
import { IContext } from "src/types/IContext.types";
import { useNotification } from "@contexts/Notification.context";

import { SKEY_TIX_API_URL } from "@config/config";
import { PATHS } from "src/router/paths";

import { OrderType } from "@src/types/api/OrderType.types";
import { ItemsWithPaginations } from "@src/types/api/ItemsWithPaginations.types";
import { HttpError } from "@services/httpErrors.enum";
import { ContextValue } from "./api.types";

import {
  OrganizationDto,
  UserDto,
  ApiKeyDto,
  EventDto,
  WorkerTokenDto,
  TicketDto,
  SubmissionDto,
  AgreementDto,
  ApiKeyAccess,
  SubmissionsOrderBy,
  OccasionalNftDto,
  ClaimingUserDto,
  ClaimingUsersOrderBy,
  UpdateOccasionalNftDto
} from "./api.dto";

const ApiClientContext = React.createContext(null as any);

export const ApiClientProvider = ({ children }: IContext) => {
  const apiClient = new ApiClient({ baseUrl: SKEY_TIX_API_URL });
  const navigate = useNavigate();

  const { t: responseT } = useTranslation("apiResponse");
  const { notify } = useNotification();

  // Base ********************************************************
  const _request = async <T,>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    try {
      return await apiClient.request<T>(config);
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        notify("error", responseT("errors.unauthorized"));
        navigate(PATHS.login.path);
      }

      throw e;
    }
  };

  // Auth ********************************************************
  const login = async (email: string, password: string) => {
    try {
      const res = await apiClient.login(email, password);

      if (res.status === 201) {
        navigate(PATHS.main.path);
      }
    } catch (e) {
      return notify("error", responseT("errors.invalidCredentials"));
    }
  };

  const logout = async () => {
    try {
      const res = await apiClient.logout();

      if (res.status === 204) {
        navigate(PATHS.login.path);
      }
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        navigate(PATHS.login.path);
      }
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const res = await apiClient.request({
        method: "GET",
        url: "/auth/resetPassword",
        params: {
          email
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.resetPassword"));
      }
      return res;
    } catch (e) {
      return notify("error", responseT("errors.base"));
    }
  };

  const setNewPassword = async (token: string, newPassword: string) => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: "/auth/resetPassword",
        data: {
          token,
          newPassword
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.setNewPassword"));
        navigate(PATHS.passwordChanged.path);
      }
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 403) {
        notify("error", responseT("errors.invalidResetPasswrodToken"));
      } else {
        notify("error", responseT("errors.base"));
      }

      throw e;
    }
  };

  const register = async (email: string, password: string, organizationName: string) => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: "/auth/register",
        data: {
          email,
          password,
          name: organizationName
        }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 400 && e.response?.data?.message === HttpError.ORGANIZATION_NAME_ALREADY_EXISTS) {
        notify("error", responseT("errors.registerOrganizationAlreadyExits"));
      } else if (e.response?.status === 400 && e.response?.data?.message === HttpError.USER_EMAIL_ALREADY_EXISTS) {
        notify("error", responseT("errors.registerEmailAlreadyExits"));
      } else {
        notify("error", responseT("errors.registerBase"));
      }

      throw e;
    }
  };

  const activateUser = async (token: string) => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: "/auth/activateUser",
        params: {
          token
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.activateUser"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      // if (e.response?.status === 400 && e.response?.data?.message === HttpError.USER_NOT_FOUND) {
      //   notify("error", responseT("errors.activateUserNotFoundUser"));
      // } else if (e.response?.status === 400 && e.response?.data?.message === HttpError.USER_ALREADY_ACTIVATED) {
      //   notify("error", responseT("errors.activateUserAlreadyActivated"));
      // } else {
      //   notify("error", responseT("errors.activateUserBase"));
      // }

      throw e;
    }
  };

  const resendActivationMail = async (token: string) => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: "/auth/resendActivationMail",
        params: {
          token
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.resendActivationMail"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      notify("error", responseT("errors.resendActivationMail"));

      throw e;
    }
  };
  // Users *******************************************************
  const getMeData = async () => {
    try {
      const res = await _request<UserDto>({ method: "GET", url: "/auth/me" });
      return res;
    } catch (e) {
      notify("error", responseT("errors.getMeData"));
      throw e;
    }
  };

  // Organizations ************************************************
  const getOrganizations = async () => {
    try {
      const res = await _request<OrganizationDto[]>({ method: "GET", url: "/organizations" });
      return res;
    } catch (e) {
      notify("error", responseT("errors.getOrganizations"));
      throw e;
    }
  };

  const getOrganizationApiKeys = async (organizationId: string) => {
    try {
      const res = await _request<ApiKeyDto[]>({
        method: "GET",
        url: `/organizations/${organizationId}/apiKeys`
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else {
        notify("error", responseT("errors.getOrganizationApiKeys"));
      }

      throw e;
    }
  };

  const updateOrganization = async (organizationId: string, name: string, email: string, logo: File | null) => {
    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("email", email);
      formData.append("logo", logo || "");

      const res = await _request<OrganizationDto>({
        method: "PUT",
        url: `/organizations/${organizationId}`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      if (res.status === 200) {
        notify("success", responseT("success.updateOrganization"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else {
        notify("error", responseT("errors.updateOrganization"));
      }

      throw e;
    }
  };

  const uploadOrganizationLogo = async (organizationId: string, logo?: File | string) => {
    try {
      const formData = new FormData();

      if (logo !== undefined) {
        formData.append("logo", logo || "");
      }

      const res = await _request<OrganizationDto>({
        method: "PUT",
        url: `/organizations/${organizationId}`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      if (res.status === 200) {
        notify("success", responseT("success.updateOrganization"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else {
        notify("error", responseT("errors.updateOrganization"));
      }

      throw e;
    }
  };

  // Api keys ****************************************************
  const createApiKey = async (organizationId: string, label: string, exp: string, access: ApiKeyAccess) => {
    try {
      const res = await _request<ApiKeyDto>({
        method: "POST",
        url: `/organizations/${organizationId}/apiKeys`,
        data: {
          label,
          exp: exp || undefined,
          access
        }
      });

      if (res.status === 201) {
        notify("success", responseT("success.createApiKey"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else {
        notify("error", responseT("errors.createApiKey"));
      }

      throw e;
    }
  };

  const removeApiKey = async (apiKeyId: string) => {
    try {
      const res = await _request({
        method: "DELETE",
        url: `/apiKeys/${apiKeyId}`
      });

      if (res.status === 200) {
        notify("success", responseT("success.removeApiKey"));
      }

      return res;
    } catch (e) {
      notify("error", responseT("errors.removeApiKey"));

      throw e;
    }
  };

  // Events ******************************************************
  const getEvents = async (organizationId: string) => {
    try {
      const res = await _request<EventDto[]>({
        method: "GET",
        url: `/organizations/${organizationId}/events`
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else {
        notify("error", responseT("errors.getEvents"));
      }

      throw e;
    }
  };

  const createEvent = async (
    organizationId: string,
    eventName: string,
    startDate: string,
    endDate: string,
    timezone: string,
    url: string,
    locationName: string,
    locationAddress: string,
    locationUrl: string,
    organizatorEmail: string
  ) => {
    try {
      const res = await _request<EventDto>({
        method: "POST",
        url: `/organizations/${organizationId}/events`,
        data: {
          email: organizatorEmail,
          name: eventName,
          start: startDate,
          end: endDate,
          offset: 3600000,
          timezone,
          url: url || undefined,
          location: {
            name: locationName || undefined,
            address: locationAddress || undefined,
            url: locationUrl || undefined
          }
        }
      });

      if (res.status === 201) {
        notify("success", responseT("success.createEvent"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!organizationId || e.response?.status === 404) {
        notify("error", responseT("errors.noOrganization"));
      } else if (
        e.response?.status === 400 &&
        e.response.data.message.includes("timezone must be one of the following values")
      ) {
        notify("error", responseT("errors.invalidTimezone"));
      } else {
        notify("error", responseT("errors.createEvent"));
      }

      throw e;
    }
  };

  const updateEvent = async (
    eventId: string,
    eventName: string,
    startDate: string,
    endDate: string,
    timezone: string,
    url: string,
    locationName: string,
    locationAddress: string,
    locationUrl: string,
    organizatorEmail: string
  ) => {
    try {
      const res = await _request<EventDto>({
        method: "PUT",
        url: `/events/${eventId}`,
        data: {
          email: organizatorEmail,
          name: eventName,
          start: startDate,
          end: endDate,
          timezone,
          offset: 3600000,
          url: url || null,
          location: {
            name: locationName || null,
            address: locationAddress || null,
            url: locationUrl || null
          }
        }
      });

      if (res.status === 200) {
        notify("success", responseT("success.updateEvent"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else if (
        e.response?.status === 400 &&
        e.response.data.message.includes("timezone must be one of the following values")
      ) {
        notify("error", responseT("errors.invalidTimezone"));
      } else {
        notify("error", responseT("errors.updateEvent"));
      }

      throw e;
    }
  };

  const uploadEventLogo = async (eventId: string, logo?: File | string) => {
    const formData = new FormData();

    if (logo !== undefined) {
      formData.append("logo", logo || "");
    }

    try {
      const res = await _request({
        method: "PUT",
        url: `/events/${eventId}/files`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      if (res.status === 200) {
        notify("success", responseT("success.uploadEventLogo"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.uploadEventLogo"));
      }

      throw e;
    }
  };

  const removeEvent = async (eventId: string) => {
    try {
      const res = await _request({
        method: "DELETE",
        url: `/events/${eventId}`
      });

      if (res.status === 204) {
        notify("success", responseT("success.removeEvent"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.removeEvent"));
      }

      throw e;
    }
  };

  // Event agreements ********************************************
  const updateEventAgreements = async (eventId: string, agreements: Omit<AgreementDto, "id">[]) => {
    try {
      const res = await _request<AgreementDto[]>({
        method: "PUT",
        url: `/events/${eventId}/agreements`,
        data: {
          agreements
        }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else if (e.response?.status === 400 && e.response.data.message === HttpError.EVENT_AGREEMENTS_LOCKED) {
        notify("error", responseT("errors.updateEventAgreementsLocked"));
      } else {
        notify("error", responseT("errors.updateEventAgreements"));
      }

      throw e;
    }
  };

  // Event submissions *******************************************
  const getEventSubmissions = async (
    eventId: string,
    page = 1,
    perPage = 20,
    order?: OrderType,
    orderBy?: SubmissionsOrderBy,
    search?: string
  ) => {
    try {
      const res = await _request<ItemsWithPaginations<SubmissionDto>>({
        method: "GET",
        url: `/events/${eventId}/submissions`,
        params: { page, perPage, order, orderBy, search }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getEventSubmissions"));
      }

      throw e;
    }
  };

  // Event page **************************************************
  const updateEventPage = async (
    eventId: string,
    enableSubmissions: boolean,
    uniqueSubmission: boolean,
    automaticTicketSending: boolean,
    submissionsButtonDisabled: boolean,
    submissionEnd: string
  ) => {
    try {
      const res = await _request<EventDto>({
        method: "PUT",
        url: `/events/${eventId}`,
        data: {
          page: {
            enableSubmissions,
            uniqueSubmission,
            automaticTicketSending,
            submissionsButtonDisabled,
            submissionEnd
          }
        }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.updateEventPage"));
      }

      throw e;
    }
  };

  const uploadEventPageImage = async (eventId: string, pageImage?: File | string) => {
    const formData = new FormData();

    if (pageImage !== undefined) {
      formData.append("pageImage", pageImage || "");
    }

    try {
      const res = await _request({
        method: "PUT",
        url: `/events/${eventId}/files`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.uploadEventPageImage"));
      }

      throw e;
    }
  };

  const updateEventPageContent = async (eventId: string, pageContent: string) => {
    try {
      const res = await _request<EventDto>({
        method: "PUT",
        url: `/events/${eventId}`,
        data: {
          page: {
            content: pageContent || null
          }
        }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.updateEventPageContent"));
      }

      throw e;
    }
  };

  // Worker tokens ***********************************************
  const getWorkerTokens = async (eventId: string) => {
    try {
      const res = await _request<WorkerTokenDto[]>({
        method: "GET",
        url: `/events/${eventId}/workerTokens`
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getWorkerTokens"));
      }

      throw e;
    }
  };

  const createWorkerToken = async (eventId: string, label: string, controller: string) => {
    try {
      const res = await _request<WorkerTokenDto>({
        method: "POST",
        url: `/events/${eventId}/workerTokens`,
        data: {
          label,
          controller: controller || undefined
        }
      });

      if (res.status === 201) {
        notify("success", responseT("success.createWorkerToken"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.createWorkerToken"));
      }

      throw e;
    }
  };

  const updateWorkerToken = async (workerTokenId: string, label: string, controller: string) => {
    try {
      const res = await _request<WorkerTokenDto>({
        method: "PUT",
        url: `/workerTokens/${workerTokenId}`,
        data: {
          label,
          controller: controller || null
        }
      });

      if (res.status === 200) {
        notify("success", responseT("success.updateWorkerToken"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!workerTokenId || e.response?.status === 404) {
        notify("error", responseT("errors.noWorkerToken"));
      } else {
        notify("error", responseT("errors.updateWorkerToken"));
      }

      throw e;
    }
  };

  const removeWorkerToken = async (workerTokenId: string) => {
    try {
      const res = await _request({
        method: "DELETE",
        url: `/workerTokens/${workerTokenId}`
      });

      if (res.status === 200) {
        notify("success", responseT("success.removeWorkerToken"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!workerTokenId || e.response?.status === 404) {
        notify("error", responseT("errors.noWorkerToken"));
      } else {
        notify("error", responseT("errors.removeWorkerToken"));
      }

      throw e;
    }
  };

  const getWorkerTokenQr = async (workerTokenId: string) => {
    try {
      const res = await _request<ArrayBuffer>({
        method: "GET",
        url: `/workerTokens/${workerTokenId}/qr`,
        responseType: "arraybuffer"
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!workerTokenId || e.response?.status === 404) {
        notify("error", responseT("errors.noWorkerToken"));
      } else {
        notify("error", responseT("errors.getWorkerTokenQr"));
      }

      throw e;
    }
  };

  // Tickets *****************************************************
  const getTicketsForEvent = async (eventId: string, page = 1, perPage = 20) => {
    try {
      const res = await _request<ItemsWithPaginations<TicketDto>>({
        method: "GET",
        url: `/events/${eventId}/tickets`,
        params: { page, perPage }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getTicketsForEvent"));
      }

      throw e;
    }
  };

  const getTicketFile = async (ticketId: string, type: "qr" | "ticketCard") => {
    try {
      const res = await _request<ArrayBuffer>({
        method: "POST",
        url: `/tickets/${ticketId}/view`,
        responseType: "arraybuffer",
        params: { type }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!ticketId || e.response?.status === 404) {
        notify("error", responseT("errors.noTicket"));
      } else {
        notify("error", responseT("errors.getTicketFile"));
      }

      throw e;
    }
  };

  const getTicketsFiles = async (eventId: string, type: "qr" | "ticketCard") => {
    try {
      const res = await _request<Blob>({
        method: "POST",
        url: `/events/${eventId}/tickets/view`,
        responseType: "blob",
        params: { type },
        timeout: 300_000 // 5 min
      });

      if (res.status === 201) {
        notify("success", responseT("success.getTicketsFiles"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getTicketsFiles"));
      }

      throw e;
    }
  };

  const sendTickets = async (eventId: string, whitelist?: string[] | string) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/events/${eventId}/sendTickets`,
        params: { whitelist }
      });
      if (res.status === 204) {
        notify("success", responseT("success.sendTickets"));
      }

      return res;
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else if (
        e.response?.status === 400 &&
        e.response?.data?.message === HttpError.NOT_ENOUGH_TICKETS_FOR_SUBMISSIONS
      ) {
        notify("error", responseT("errors.notEnoughTickets"));
      } else {
        notify("error", responseT("errors.sendTickets"));
      }

      throw e;
    }
  };

  // Support *****************************************************
  const sendEmail = async (message: string) => {
    try {
      const res = await _request({
        method: "POST",
        url: "/support/helpdesk",
        data: {
          message
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.sendEmail"));
      }

      return res;
    } catch (e) {
      notify("error", responseT("errors.base"));

      throw e;
    }
  };

  const orderTickets = async (eventId: string, ticketsCount: number) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/support/events/${eventId}/orderTickets`,
        data: {
          number: ticketsCount
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.orderTickets"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.orderTickets"));
      }

      throw e;
    }
  };

  const orderNftModule = async (eventId: string) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/support/events/${eventId}/orderNftModule`
      });

      if (res.status === 204) {
        notify("success", responseT("success.orderNftModule"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.orderNftModule"));
      }

      throw e;
    }
  };

  // Files *******************************************************
  const getFile = async (fileId: string) => {
    try {
      const res = await _request<ArrayBuffer>({
        method: "GET",
        url: `/files/${fileId}`,
        responseType: "arraybuffer"
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!fileId || e.response?.status === 404) {
        notify("error", responseT("errors.noFile"));
      } else {
        notify("error", responseT("errors.getFile"));
      }

      throw e;
    }
  };

  // Statistics **************************************************
  const getAttendanceList = async (eventId: string) => {
    try {
      const res = await _request<Blob>({
        method: "GET",
        url: `/statistics/${eventId}/attendanceList`,
        responseType: "blob"
      });

      if (res.status === 200) {
        notify("success", responseT("success.getAttendanceList"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getAttendanceList"));
      }

      throw e;
    }
  };

  const getAgreementsList = async (eventId: string) => {
    try {
      const res = await _request<Blob>({
        method: "GET",
        url: `/statistics/${eventId}/agreementsList`,
        responseType: "blob"
      });

      if (res.status === 200) {
        notify("success", responseT("success.getAgreementsList"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getAgreementsList"));
      }

      throw e;
    }
  };

  // Occasional Nfts *********************************************
  const getOccasionalNftById = async (eventId: string) => {
    try {
      const res = await _request<OccasionalNftDto>({
        method: "GET",
        url: `/occasionalNfts/${eventId}`
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getOccasionalNftById"));
      }

      throw e;
    }
  };

  const createOccasionalNft = async (eventId: string, name: string, image: File | string) => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("image", image);

    try {
      const res = await _request<OccasionalNftDto>({
        method: "POST",
        url: `/occasionalNfts/${eventId}`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      if (res.status === 200) {
        notify("success", responseT("success.createOccasionalNft"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.createOccasionalNft"));
      }

      throw e;
    }
  };

  const updateOccasionalNft = async (eventId: string, payload: UpdateOccasionalNftDto) => {
    const { name, image, occasionalNFTRule } = payload;
    const formData = new FormData();

    name && formData.append("name", name);
    occasionalNFTRule && formData.append("occasionalNFTRule", occasionalNFTRule);
    image && formData.append("image", image);

    try {
      const res = await _request<OccasionalNftDto>({
        method: "PUT",
        url: `/occasionalNfts/${eventId}`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      });

      if (res.status === 200) {
        notify("success", responseT("success.updateOccasionalNft"));
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.updateOccasionalNft"));
      }

      throw e;
    }
  };

  const getClaimingUsersByEventId = async (
    eventId: string,
    page = 1,
    perPage = 20,
    order?: OrderType,
    orderBy?: ClaimingUsersOrderBy
  ) => {
    try {
      const res = await _request<ItemsWithPaginations<ClaimingUserDto>>({
        method: "GET",
        url: `/occasionalNfts/${eventId}/users`,
        params: { page, perPage, order, orderBy }
      });

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.getClaimingUsersByEventId"));
      }

      throw e;
    }
  };

  const sendNftsToAllSubmissions = async (eventId: string) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/occasionalNfts/${eventId}/sendSubmissions`
      });

      if (res.status === 204) {
        notify("success", responseT("success.sendOccasionalNftToAllSubmissions"));
      }

      return res;
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.sendOccasionalNftToAllSubmissions"));
      }

      throw e;
    }
  };

  const sendNftsToAllSelected = async (eventId: string, emailsList: { email: string; ticketId: string }[]) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/occasionalNfts/${eventId}/sendEmails`,
        data: {
          emailsList
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.sendOccasionalNftToAllSelected"));
      }

      return res;
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (!eventId || e.response?.status === 404) {
        notify("error", responseT("errors.noEvent"));
      } else {
        notify("error", responseT("errors.sendOccasionalNftToAllSelected"));
      }

      throw e;
    }
  };

  const contextValue: ContextValue = {
    // Users *******************************************************
    login,
    logout,
    resetPassword,
    setNewPassword,
    register,
    activateUser,
    resendActivationMail,
    // Users *******************************************************
    getMeData,
    // Organizations ***********************************************
    getOrganizations,
    getOrganizationApiKeys,
    updateOrganization,
    uploadOrganizationLogo,
    // Api keys ****************************************************
    createApiKey,
    removeApiKey,
    // Events ******************************************************
    getEvents,
    createEvent,
    updateEvent,
    uploadEventLogo,
    removeEvent,
    // Event agreements ********************************************
    updateEventAgreements,
    // Event submissions *******************************************
    getEventSubmissions,
    // Event page **************************************************
    updateEventPage,
    uploadEventPageImage,
    updateEventPageContent,
    // Worker tokens ***********************************************
    getWorkerTokens,
    createWorkerToken,
    updateWorkerToken,
    removeWorkerToken,
    getWorkerTokenQr,
    // Tickets *****************************************************
    getTicketsForEvent,
    getTicketFile,
    getTicketsFiles,
    sendTickets,
    // Support *****************************************************
    sendEmail,
    orderTickets,
    orderNftModule,
    // Files *******************************************************
    getFile,
    // Statistics **************************************************
    getAttendanceList,
    getAgreementsList,
    // Occasional Nfts *********************************************
    getOccasionalNftById,
    createOccasionalNft,
    updateOccasionalNft,
    getClaimingUsersByEventId,
    sendNftsToAllSubmissions,
    sendNftsToAllSelected
  };

  return <ApiClientContext.Provider value={contextValue}>{children}</ApiClientContext.Provider>;
};

export const useApiClient = (): ContextValue => React.useContext(ApiClientContext);
